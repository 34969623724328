// ----------------------------------------------------------------------

import { _id } from 'src/_mock/assets';

const MOCK_ID = _id[1];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  HEALTHCHECK: '/healthcheck',
};

// ----------------------------------------------------------------------

export const paths = {
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  // HEALTHCHECK
  healthcheck: ROOTS.HEALTHCHECK,
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,

    timeline: {
      root: `${ROOTS.DASHBOARD}/timeline`,
    },
    room: {
      root: `${ROOTS.DASHBOARD}/room`,
    },
    reservation: {
      root: `${ROOTS.DASHBOARD}/reservation`,
      list: `${ROOTS.DASHBOARD}/reservation/list`,
      ota: {
        list: `${ROOTS.DASHBOARD}/reservation/ota/list`,
      },
    },
    payment: {
      root: `${ROOTS.DASHBOARD}/payment`,
      dashboard: `${ROOTS.DASHBOARD}/payment/statistics`,
      list: `${ROOTS.DASHBOARD}/payment/list`,
      sales: {
        list: `${ROOTS.DASHBOARD}/payment/sales/list`,
      },
    },
    operationManagement: {
      root: `${ROOTS.DASHBOARD}/operation-management`,
      room: {
        setting: `${ROOTS.DASHBOARD}/operation-management/room/setting`,
      },
      roomType: {
        setting: `${ROOTS.DASHBOARD}/operation-management/room-type/setting`,
      },
      accommodation: {
        information: `${ROOTS.DASHBOARD}/operation-management/accommodation/information`,
        operation: `${ROOTS.DASHBOARD}/operation-management/accommodation/operation`,
        edit: (id: number) => `${ROOTS.DASHBOARD}/operation-management/accommodation/${id}/edit`,
      },
    },
    interconnection: {
      root: `${ROOTS.DASHBOARD}/interconnection`,
      details: (id: number) => `${ROOTS.DASHBOARD}/interconnection/${id}`,
    },

    member: {
      root: `${ROOTS.DASHBOARD}/member/list`,
      new: `${ROOTS.DASHBOARD}/member/new`,
      list: `${ROOTS.DASHBOARD}/member/list`,
      account: `${ROOTS.DASHBOARD}/member/account`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/member/${id}/edit`,
    },
  },
};
